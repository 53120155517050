<template>
  <div @keyup.enter.capture="triggerClickOnSearch">
    <label class="InvoicesSearch__page-label">Approved Invoices</label>
    <!-- advanced search section (searches only approved): EMPLOYEE view -->
    <!-- changes to a component must be reflected in the CLIENT view -->
    <div
      v-if="!userIsClient"
      class="column"
    >
      <div class="InvoicesSearch__search-wrapper">
        <!-- Client and Debtor -->
        <base-client-search
          @selected="setClientID"
          class="InvoicesSearch__client-input"
          :client-i-d-from-query="client_id"
          :label="true"
          :label-for="'Client'"
        >
          <template v-slot:label>
            CLIENT
          </template>
        </base-client-search>
        <base-debtor-search
          @selected="setDebtorID"
          class="InvoicesSearch__debtor-input"
          :debtor-i-d-from-query="debtor_id"
          :label="true"
          :label-for="'Debtor'"
        >
          <template v-slot:label>
            DEBTOR
          </template>
        </base-debtor-search>

        <!-- Load # -->
        <base-input
          v-model.trim="load_number"
          class="InvoicesSearch__query-input"
          :instructions="instructions.LOAD_NUMBER_INSTRUCTION"
          :label="true"
          :label-for="'Load-Number'"
          :type="'text'"
          :valid="loadNumberValid"
        >
          LOAD #
        </base-input>

        <!-- Invoice ID # -->
        <base-input
          v-model.trim="invoice_id"
          class="InvoicesSearch__query-input"
          :instructions="instructions.ID_INSTRUCTION"
          :label="true"
          :label-for="'Invoice-ID'"
          :type="'text'"
          :valid="invoiceIdValid"
        >
          INVOICE #
        </base-input>

        <!-- Search & Filter Btns -->
        <base-button
          @click="clickOnSearch"
          class="InvoicesSearch__buttons bg-white fc-blue"
          data-cy="invoice-search-submit-btn"
        >
          <i class="fa fa-search-left" />
          Search
        </base-button>

        <base-button
          @click="showFilters = !showFilters"
          aria-label="Toggles the display of additional search fliter options"
          :class="[
            'InvoicesSearch__buttons InvoicesSearch__buttons-filter bg-white fc-blue',
            { 'InvoicesSearch__buttons--outlined': showFilters }
          ]"
          data-cy="invoice-search-filters-btn"
          id="Testing__FiltersBtn"
          name="Filters"
        >
          <i class="fa fa-optionbars" />
          Filters
        </base-button>
      </div>

      <transition name="Transition__opacity-fade">
        <div
          v-show="showFilters"
          class="InvoicesSearch__filters-wrapper"
        >
          <!--
            This is required because the "Days Outstanding" label
            is suppose to run over BOTH inputs.
          -->
          <div class="InvoicesSearch__days-outstanding-wrapper column">
            <label
              class="InvoicesSearch__days-outstanding-label uppercase"
              for="DaysOutstanding"
            >
              Days Outstanding
            </label>
            <div class="row">
              <base-input
                v-model.trim="daysOutstandingFrom"
                class="InvoicesSearch__days-outstanding-inputs"
                data-cy="invoice-search-days-outstanding-from"
                id="DaysOutstanding"
                :instructions="instructions.DAYS_OUTSTANDING_FROM_INSTRUCTION"
                :label-for="'daysOutstandingFrom'"
                :placeholder="'From'"
                :type="'text'"
                :valid="daysOutstandingFromValid"
              />
              <base-input
                v-model.trim="daysOutstandingTo"
                class="InvoicesSearch__days-outstanding-inputs"
                data-cy="invoice-search-days-outstanding-to"
                id="DaysOutstanding"
                :instructions="instructions.DAYS_OUTSTANDING_TO_INSTRUCTION"
                :label-for="'daysOutstandingTo'"
                :placeholder="'To'"
                :type="'text'"
                :valid="daysOutstandingToValid"
              />
            </div>
          </div>

          <!-- Additional Filters -->
          <base-toggle
            @toggle="setDeliveryRequirements"
            :active-option="activeAdditionalFilter"
            aria-label="Additional filter options (toggle)"
            class="InvoicesSearch__additional-filters-toggle"
            :label="'ADDITIONAL FILTERS'"
            :toggles="['all', 'online', 'originals', 'copies']"
          />

          <!-- Predefined Search  -->
          <v-select
            v-model="predefinedInput"
            @input="setPredefinedSearch"
            aria-label="Predefined Searches Select Input"
            class="InvoicesSearch__predefined-select mt-25"
            :clearable="false"
            :options="predefinedOptions"
            :placeholder="'Predefined'"
          />

          <!-- Flag Search  -->
          <v-select
            v-model="flagInput"
            aria-label="Flag Select Input"
            class="InvoicesSearch__flag-select mt-25"
            :clearable="false"
            data-cy="approved-search-flag-filter"
            :options="flagOptions"
            :placeholder="'Flag:'"
          />
        </div>
      </transition>
    </div>

    <!-- advanced search section (searches only approved): CLIENT view -->
    <!-- changes to a component must be reflected in the EMPLOYEE view -->
    <div
      v-else
      class="InvoicesSearch__search-wrapper"
    >
      <!-- Debtor -->
      <base-debtor-search
        @selected="setDebtorID"
        class="InvoicesSearch__debtor-input"
        :debtor-i-d-from-query="debtor_id"
        :label="true"
        :label-for="'Debtor'"
      >
        <template v-slot:label>
          DEBTOR
        </template>
      </base-debtor-search>
      <!-- Invoice # && Load # -->
      <base-input
        v-model.trim="query"
        class="InvoicesSearch__query-input"
        :instructions="instructions.QUERY_INSTRUCTION"
        :label="true"
        :label-for="'Query'"
        :type="'text'"
        :valid="queryValid"
      >
        LOAD #
      </base-input>
      <!-- Search btn -->
      <base-button
        @click="clickOnSearch"
        class="InvoicesSearch__buttons bg-white fc-blue"
      >
        <i class="fa fa-search-left" />
        Search
      </base-button>
    </div>

    <!-- Total invoices and value + Sort && Collapse toggles -->
    <div class="row row--align-center mb-25 mt-25 width-100">
      <label
        v-if="invoices.total > 0"
        class="InvoicesSearch__search-results-label"
        id="Testing__TotalSearchResults"
      >
        {{ invoices.total }} Total
        ({{
          Intl
            .NumberFormat('en-US', { style: 'currency', currency: 'USD' })
            .format(invoices.sum / 100)
        }})
      </label>
      <label
        v-else
        class="InvoicesSearch__search-results-label"
        id="Testing__TotalSearchResults"
      >
        There are no invoices
      </label>

      <v-select
        v-if="!userIsClient"
        v-model="sortByInput"
        @input="setSortBy"
        class="InvoicesSearch__sort-by-select"
        :clearable="false"
        data-cy="invoice-search-sort-by"
        :options="sortByOptions"
      />
      <base-toggle
        v-if="!userIsClient"
        @toggle="emitCollapseStateChange"
        :active-option="expandCollapse"
        aria-label="Collapse or expand items"
        class="InvoicesSearch__expand-toggle"
        :toggles="expandCollapseToggles"
      />

      <transition
        mode="out-in"
        name="Transition__opacity-fade"
      >
        <!-- Loading Indicator -->
        <div
          v-if="!userIsClient && !reportFinished"
          :key="'loading-indicator'"
        >
          <div class="InvoicesSearch__loading-indicator">
            <i class="fa fa-processing fa-spin fa--none fc-light fs-60" />
          </div>
        </div>
        <base-button
          v-else-if="!userIsClient"
          @click="reportModalVisible = true"
          @keydown.enter="reportModalVisible = true"
          class="bg-trans"
        >
          <i class="InvoicesSearch__aging-icon fa fa-invoice fa-20" />
        </base-button>
      </transition>
    </div>

    <!-- AGING DOWNLOAD MODAL (GIVES USER FILE TYPE DOWNLOAD OPTION) -->
    <transition name="Transition__fade">
      <base-modal v-if="reportModalVisible">
        <template v-slot:label>
          What format do you want?
        </template>

        <template v-slot:actions>
          <base-button
            @click="downloadInvoiceReport('xlsx')"
            class="bg-green fc-white mr-8"
          >
            EXCEL
          </base-button>
          <base-button
            @click="downloadInvoiceReport('pdf')"
            class="bg-red fc-white"
          >
            PDF
          </base-button>
          <base-button
            @click="reportModalVisible = false"
            class="bg-light fc-white ml-8"
          >
            CANCEL
          </base-button>
        </template>
      </base-modal>
    </transition>
  </div>
</template>

<script>
// Packages
import moment from 'moment-timezone'
// Helpers
import {
  Invoice
} from '../utils/api'
import {
  stringifyObjectToQueryString,
} from '../utils/helpers'
// Components
import BaseButton from './base-button.vue'
import BaseClientSearch from './base-client-search.vue'
import BaseDebtorSearch from './base-debtor-search.vue'
import BaseInput from './base-input.vue'
import BaseModal from './base-modal.vue'
import BaseToggle from './base-toggle.vue'
// Mixins
import {
  ValidationMixin
} from '../utils/validation-mixin'

export default {
  name: 'ApprovedSearch',

  components: {
    BaseButton,
    BaseClientSearch,
    BaseDebtorSearch,
    BaseInput,
    BaseModal,
    BaseToggle,
  },

  mixins: [ValidationMixin],

  props: {
    page: {
      type: Number,
      required: true,
    },
    userIsClient: {
      type: Boolean,
      required: true,
    },
  },

  async created () {
    this.$store.commit('CLEAR_INVOICES')

    // Query string? Compare variables with component data; if different, perform search
    const queryString = window.location.search
    const queryParams = new URLSearchParams(queryString)

    // Check for search variables, udpate component data
    if (queryString) {
      // Days Outstanding
      if (queryParams.has('daysOutstandingTo')) {
        if (queryParams.get('daysOutstandingTo') !== this.daysOutstandingTo) {
          this.daysOutstandingTo = queryParams.get('daysOutstandingTo')
        }
      }
      if (queryParams.has('daysOutstandingFrom')) {
        if (queryParams.get('daysOutstandingFrom') !== this.daysOutstandingFrom) {
          this.daysOutstandingFrom = queryParams.get('daysOutstandingFrom')
        }
      }

      // Client ID
      if (queryParams.has('client_id')) {
        if (queryParams.get('client_id') !== this.client_id) {
          this.client_id = queryParams.get('client_id')
        }
      }

      // Debtor ID
      if (queryParams.has('debtor_id')) {
        if (queryParams.get('debtor_id') !== this.debtor_id) {
          this.debtor_id = queryParams.get('debtor_id')
        }
      }

      // Requires (copies, originals, online)
      if (queryParams.has('require_copies')) {
        if (queryParams.get('require_copies') !== this.require_copies) {
          this.activeAdditionalFilter = 'copies'
          this.require_copies = (queryParams.get('require_copies') === 'true')
        }
      }
      if (queryParams.has('require_online_submit')) {
        if (queryParams.get('require_online_submit') !== this.require_online_submit) {
          this.activeAdditionalFilter = 'online'
          this.require_online_submit = (queryParams.get('require_online_submit') === 'true')
        }
      }
      if (queryParams.has('require_originals')) {
        if (queryParams.get('require_originals') !== this.require_originals) {
          this.activeAdditionalFilter = 'originals'
          this.require_originals = (queryParams.get('require_originals') === 'true')
        }
      }

      // invoice/load# (query) (clients-only)
      if (queryParams.has('query')) {
        if (queryParams.get('query') !== this.query) {
          this.query = queryParams.get('query')
        }
      }

      // invoice id
      if (queryParams.has('invoice_id')) {
        if (queryParams.get('invoice_id') !== this.invoice_id) {
          this.invoice_id = queryParams.get('invoice_id')
        }
      }

      // load #
      if (queryParams.has('load_number')) {
        if (queryParams.get('load_number') !== this.load_number) {
          this.load_number = queryParams.get('load_number')
        }
      }

      // Predefined
      if (queryParams.has('predefined')) {
        if (queryParams.get('predefined') !== this.predefinedInput) {
          const predefined = queryParams.get('predefined')
          this.predefinedInput = predefined
          this.setPredefinedSearch(predefined)
        }
      }

      // Flag
      if (queryParams.has('flag')) {
        if (queryParams.get('flag') !== this.flagInput) {
          const flag = queryParams.get('flag')
          this.flagInput = flag
        }
      }

      // Sort By
      if (queryParams.has('sort_by')) {
        if (queryParams.get('sort_by') !== this.sortByInput) {
          this.sortByInput = queryParams.get('sort_by')
          this.$emit('sort-by', queryParams.get('sort_by')) // Required for invoice categorization
        }
      }
    }

    // If userIsClient, set client_id and sort by
    if (this.userIsClient) {
      const client = JSON.parse(localStorage.getItem('user')).client
      // Set after queryString check to ensure client can't change the sort via URL
      this.client_id = client.id
      this.sortByInput = 'time'
    }

    this.search()
  },

  mounted () {
    // it needs to refresh results every 5 minutes for clients only
    if (this.userIsClient) {
      this.searchInterval = setInterval(() => {
        this.search()
      }, 5 * 60000)
    }
  },

  beforeDestroy() {
    window.removeEventListener('keyup', this.triggerClickOnSearch)
    // it should stop searching when you leave the page
    if (this.searchInterval) clearInterval(this.searchInterval)
  },

  data() {
    return {
      activeAdditionalFilter: 'all',
      approved_date: [],
      client_id: null,
      daysOutstandingFrom: '',
      daysOutstandingFromValid: true,
      daysOutstandingTo: '',
      daysOutstandingToValid: true,
      debtor_id: null,
      delayed_payments: null,
      expandCollapse: '<i class="fa fa-4-bars fa-15"></i>',
      expandCollapseToggles: [
        '<i class="fa fa-4-bars fa-15"></i>',
        '<i class="fa fa-hamburger fa-15"></i>',
      ],
      flagged: null,
      flagInput: 'none',
      flagOptions: [
        'none',
        'approved other',
        'debtor claim',
        'filed on bond',
        'missing paperwork',
        'originals required',
        'paid to client',
        'payment',
        'unreadable',
      ],
      invoice_id: '',
      invoiceIdValid: true,
      is_buyout: null,
      load_number: '',
      loadNumberValid: true,
      predefinedInput: 'none',
      predefinedOptions: [
        'none',
        'delayed payments',
        'unopened emails',
        'flagged',
        'buyout',
      ],
      query: '',
      queryValid: true,
      require_copies: null,
      require_online_submit: null,
      require_originals: null,
      reportFinished: true,
      reportModalVisible: false,
      resultsPerPage: 25,
      searchInterval: null,
      showFilters: false,
      sortByInput: 'time',
      sortByOptions: ['time', 'total', 'client', 'debtor'],
      status: 'approved',
      unopened_emails: null,
    }
  },

  computed: {
    invoices () {
      return this.$store.getters.invoices
    }
  },

  watch: {
    page () {
      this.search()
    },
  },

  methods: {
    calculateDaysOutstanding() {
      this.approved_date = []
      // From Paul, if we were searching for 5 to 25:
      // Days Outstanding To === 25
      // Days Outstanding From === 5
      if (this.daysOutstandingTo.length) {
        const date = moment().startOf('day').subtract(this.daysOutstandingTo, 'days').format('x')
        this.approved_date.push(`approved_date >= ${date}`)
      }
      if (this.daysOutstandingFrom.length) {
        const date = moment().startOf('day').subtract(this.daysOutstandingFrom, 'days').format('x')
        this.approved_date.push(`approved_date <= ${date}`)
      }
    },

    clickOnSearch() {
      // Search is performed with this.page changes, but if it won't change, trigger search
      // This prevents double search being triggered when performing a new search when NOT
      // on page one of the current results
      if (this.page !== 1) this.$emit('reset-pagination')
      else this.search()
    },

    configureSearchParams () {
      let sort_by = 'approved_date'
      let sort_direction = 'ASC'

      if (this.sortByInput === 'total') {
        sort_by = 'total_amount'
        sort_direction = 'DESC'
      }
      else if (this.sortByInput === 'client') {
        sort_by = 'client_approved_total_amount'
        sort_direction = 'DESC'
      }
      else if (this.sortByInput === 'debtor') {
        sort_by = 'debtor_approved_total_amount'
        sort_direction = 'DESC'
      }

      const params = {
        client_id: this.client_id,
        debtor_id: this.debtor_id,
        display_id: this.invoice_id || null,
        load_number: this.load_number || null,
        offset: (this.page - 1) * this.resultsPerPage,
        status: 'approved',
        sort_by,
        sort_direction
      }

      if (this.predefined === 'delayed payments') {
        const daysAgo14 = moment()
          .tz('America/New_York')
          .startOf('day')
          .subtract(14, 'days')
          .toDate()
        params.gt___payment_issue_date = daysAgo14
      }
      else if (this.predefined === 'unopened emails') {
        params.unopened_emails = true
      }
      else if (this.predefined === 'flagged') {
        params.flagged = true
      }
      else if (this.predefined === 'buyout') {
        params.is_buyout = true
      }

      // Add daysOustanding
      // From Paul, if we were searching for 5 to 25:
      // Days Outstanding To === 25
      // Days Outstanding From === 5
      if (this.daysOutstandingTo.length) {
        // Using NY timezone (EST), toDate represents how FAR to look in the past
        // Must be passed in ISO date format
        const toDate = moment()
          .tz('America/New_York')
          .startOf('day')
          .subtract(this.daysOutstandingTo, 'days')
          .toDate()
        params.to_date = toDate
      }
      if (this.daysOutstandingFrom.length) {
        // Using NY timezone (EST), fromDate represents how CLOSE to look in the past
        // Must be passed in ISO date format
        const fromDate = moment()
          .tz('America/New_York')
          .startOf('day')
          .subtract((Number(this.daysOutstandingFrom) - 1), 'days')
          .toDate()
        params.from_date = fromDate
      }

      // Add "additional filters"
      // If all require_X are false, nothing will be passed, which is "all" additional filters
      if (this.require_online_submit) {
        params.include_models = params.include_models || {}
        params.include_models.debtor = {
          where: {
            require_online_submit: true
          }
        }
      }
      else if (this.require_originals) {
        params.include_models = params.include_models || {}
        params.include_models.debtor = {
          where: {
            require_originals: true
          }
        }
      }
      else if (this.require_copies) {
        params.include_models = params.include_models || {}
        params.include_models.debtor = {
          where: {
            require_copies: true
          }
        }
      }

      // flag search
      if (this.flagInput && this.flagInput !== 'none') params.custom_flag = this.flagInput

      return params
    },

    async downloadInvoiceReport (format = 'pdf') {
      // only run report if they have debtor or client selected
      if (!this.client_id && !this.debtor_id) {
        this.reportModalVisible = false
        return this.alertWarning('You must select a client or debtor to generate a report')
      }

      this.reportModalVisible = false
      this.reportFinished = false

      try {
        // pass the search params to the b/e so it knows which invoices to grab
        const { invoice_report_url, file_name } = (await Invoice.getInvoiceReport(
          {
            ...this.configureSearchParams(),
            // 'pdf' or 'xlsx': returns the correct file type for download
            reportType: format
          }
        )).data

        const a = document.createElement('a')
        a.style.display = 'none'
        document.body.appendChild(a)
        a.href = invoice_report_url
        a.setAttribute('download', file_name)
        a.setAttribute('target', '_blank')
        a.click()
        window.URL.revokeObjectURL(a.href)
        document.body.removeChild(a)

        this.reportFinished = true
      } catch (error) {
        this.reportFinished = true
        let message = 'There was an issue downloading the aging report'
        if (error.response && error.response.data && error.response.data.message) {
          message = error.response.data.message
        }

        if (message === 'No invoices were found') {
          this.alertWarning(message)
        } else {
          this.captureSentryEvent(
            'Approved Invoices Invoice Report Error',
            {
              config: error.config,
              data: this.$data,
              details: error,
              props: this.$props,
              response: error.response,
            }
          )
          this.CError(error)
          this.requestFailure({ message })
        }
      }
    },

    emitCollapseStateChange (e) {
      this.expandCollapse = e
      this.$emit('collapsed-state-change')
    },

    getUrlString () {
      return stringifyObjectToQueryString({
        client_id: this.client_id,
        daysOutstandingFrom: this.daysOutstandingFrom,
        daysOutstandingTo: this.daysOutstandingTo,
        debtor_id: this.debtor_id,
        flag: this.flagInput && this.flagInput !== 'none' ? this.flagInput : null,
        invoice_id: this.invoice_id,
        load_number: this.load_number,
        page: this.page,
        predefined: this.predefined,
        query: this.query,
        require_copies: this.require_copies,
        require_originals: this.require_originals,
        require_online_submit: this.require_online_submit,
        sort_by: this.sortByInput,
      })
    },

    async search () {
      if (!this.validation()) return

      this.progressStart()

      // Convert dates to ################ (milliseconds)
      this.calculateDaysOutstanding()

      // Build string for parsing on load (easier than parsing algolia str)
      const urlString = this.getUrlString()

      // Tell invoices-pending.vue to reset pagination because we're performing a new search
      this.$emit('search')
      // API Request (https://fs-bobtail.github.io/bobtail/#api-Invoice-SearchInvoices)
      // searchParams are null if the user hasn't searched for anything
      // i.e. this covers the case a user just paginates the invoices from onload
      try {
        const results = (await Invoice.queryList(this.configureSearchParams(), this.resultsPerPage)).data
        // Convert URL to URL representing data of request
        window.history.replaceState({}, '', `?${urlString}`)
        this.$store.commit('STORE_INVOICES', results)
        this.progressFinish()
      }
      catch (error) {
        this.captureSentryEvent(
          'Approved Search Error',
          {
            config: error.config,
            data: this.$data,
            details: error,
            props: this.$props,
            response: error.response,
            urlString,
          }
        )
        this.CError(error)
        this.requestFailure({
          message: 'There was an issue searching for potential matching invoices',
          title: 'Request error'
        })
      }
    },

    setClientID (filter) {
      if (!filter) {
        this.client_id = null
        return
      }
      this.client_id = filter.id
    },

    setDebtorID (filter) {
      if (!filter) {
        this.debtor_id = null
        return
      }
      this.debtor_id = filter.id
    },

    setDeliveryRequirements (filter) {
      // Reset
      this.require_copies = null
      this.require_online_submit = null
      this.require_originals = null

      // Update the active filter
      this.activeAdditionalFilter = filter

      if (filter === 'all') return
      if (filter === 'copies') this.require_copies = true
      if (filter === 'online') this.require_online_submit = true
      if (filter === 'originals') this.require_originals = true
    },

    setPredefinedSearch (filter) {
      this.delayed_payments = null
      this.unopened_emails = null
      this.flagged = null
      this.is_buyout = null

      // none
      if (filter === 'none') {
        this.predefined = 'none'
        return
      }

      // delayed payments
      if (filter === 'delayed payments') {
        this.delayed_payments = true
        this.predefined = 'delayed payments'
      }

      // unopened emails
      if (filter === 'unopened emails') {
        this.unopened_emails = true
        this.predefined = 'unopened emails'
      }

      // flagged
      if (filter === 'flagged') {
        this.flagged = true
        this.predefined = 'flagged'
      }

      // buyout
      if (filter === 'buyout') {
        this.is_buyout = true
        this.predefined = 'buyout'
      }
    },

    setSortBy() {
      // We need to clear all the invoices in Vuex before initiating this change, because
      // the computed properties in base-invoice-table will try to sort the CURRENT invoices
      // before we can perform a new search with the new, proper sort
      this.$store.commit('CLEAR_INVOICES')
      // API request with updated sort value
      this.$emit('sort-by', this.sortByInput)
      // Search is performed with this.page changes, but if it won't change, trigger search
      // This prevents double search being triggered when performing a new search when NOT
      // on page one of the current results
      if (this.page !== 1) this.$emit('reset-pagination')
      else this.search()
    },

    triggerClickOnSearch () {
      // If none of the following elements
      const toMatch = document.activeElement.classList
      if (
        toMatch.contains('InvoicesSearch__buttons-filter')
        || toMatch.contains('vs__search')
      ) {
        return
      }
      this.clickOnSearch()
    },

    validation () {
      let valid = true

      // load number
      if (this.load_number && !this.isValidLoadNumber(this.load_number)) {
        this.loadNumberValid = false
        valid = false
      } else {
        this.loadNumberValid = true
      }

      // invoice id
      if (this.invoice_id && !this.isValidID(this.invoice_id)) {
        this.invoiceIdValid = false
        valid = false
      } else {
        this.invoiceIdValid = true
      }

      // query
      if (this.query && !this.isValidLoadNumber(this.query)) {
        this.queryValid = false
        valid = false
      } else {
        this.queryValid = true
      }

      // days outstanding from
      if (
        this.daysOutstandingFrom
        && !this.isValidDaysOutstanding(this.daysOutstandingFrom)
      ) {
        this.daysOutstandingFromValid = false
        valid = false
      } else {
        this.daysOutstandingFromValid = true
      }

      // days outstanding to
      if (this.daysOutstandingTo && !this.isValidDaysOutstanding(this.daysOutstandingTo)) {
        this.daysOutstandingToValid = false
        valid = false
      } else {
        this.daysOutstandingToValid = true
      }

      return valid
    },
  }
}
</script>

<style lang="sass">
@import '../styles/invoices-search.sass'
</style>
