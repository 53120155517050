var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      on: {
        "!keyup": function($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
          ) {
            return null
          }
          return _vm.triggerClickOnSearch($event)
        }
      }
    },
    [
      _c("label", { staticClass: "InvoicesSearch__page-label" }, [
        _vm._v("Approved Invoices")
      ]),
      !_vm.userIsClient
        ? _c(
            "div",
            { staticClass: "column" },
            [
              _c(
                "div",
                { staticClass: "InvoicesSearch__search-wrapper" },
                [
                  _c("base-client-search", {
                    staticClass: "InvoicesSearch__client-input",
                    attrs: {
                      "client-i-d-from-query": _vm.client_id,
                      label: true,
                      "label-for": "Client"
                    },
                    on: { selected: _vm.setClientID },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "label",
                          fn: function() {
                            return [_vm._v("\n          CLIENT\n        ")]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      false,
                      3438241474
                    )
                  }),
                  _c("base-debtor-search", {
                    staticClass: "InvoicesSearch__debtor-input",
                    attrs: {
                      "debtor-i-d-from-query": _vm.debtor_id,
                      label: true,
                      "label-for": "Debtor"
                    },
                    on: { selected: _vm.setDebtorID },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "label",
                          fn: function() {
                            return [_vm._v("\n          DEBTOR\n        ")]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      false,
                      3347883505
                    )
                  }),
                  _c(
                    "base-input",
                    {
                      staticClass: "InvoicesSearch__query-input",
                      attrs: {
                        instructions: _vm.instructions.LOAD_NUMBER_INSTRUCTION,
                        label: true,
                        "label-for": "Load-Number",
                        type: "text",
                        valid: _vm.loadNumberValid
                      },
                      model: {
                        value: _vm.load_number,
                        callback: function($$v) {
                          _vm.load_number =
                            typeof $$v === "string" ? $$v.trim() : $$v
                        },
                        expression: "load_number"
                      }
                    },
                    [_vm._v("\n        LOAD #\n      ")]
                  ),
                  _c(
                    "base-input",
                    {
                      staticClass: "InvoicesSearch__query-input",
                      attrs: {
                        instructions: _vm.instructions.ID_INSTRUCTION,
                        label: true,
                        "label-for": "Invoice-ID",
                        type: "text",
                        valid: _vm.invoiceIdValid
                      },
                      model: {
                        value: _vm.invoice_id,
                        callback: function($$v) {
                          _vm.invoice_id =
                            typeof $$v === "string" ? $$v.trim() : $$v
                        },
                        expression: "invoice_id"
                      }
                    },
                    [_vm._v("\n        INVOICE #\n      ")]
                  ),
                  _c(
                    "base-button",
                    {
                      staticClass: "InvoicesSearch__buttons bg-white fc-blue",
                      attrs: { "data-cy": "invoice-search-submit-btn" },
                      on: { click: _vm.clickOnSearch }
                    },
                    [
                      _c("i", { staticClass: "fa fa-search-left" }),
                      _vm._v("\n        Search\n      ")
                    ]
                  ),
                  _c(
                    "base-button",
                    {
                      class: [
                        "InvoicesSearch__buttons InvoicesSearch__buttons-filter bg-white fc-blue",
                        { "InvoicesSearch__buttons--outlined": _vm.showFilters }
                      ],
                      attrs: {
                        "aria-label":
                          "Toggles the display of additional search fliter options",
                        "data-cy": "invoice-search-filters-btn",
                        id: "Testing__FiltersBtn",
                        name: "Filters"
                      },
                      on: {
                        click: function($event) {
                          _vm.showFilters = !_vm.showFilters
                        }
                      }
                    },
                    [
                      _c("i", { staticClass: "fa fa-optionbars" }),
                      _vm._v("\n        Filters\n      ")
                    ]
                  )
                ],
                1
              ),
              _c(
                "transition",
                { attrs: { name: "Transition__opacity-fade" } },
                [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.showFilters,
                          expression: "showFilters"
                        }
                      ],
                      staticClass: "InvoicesSearch__filters-wrapper"
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "InvoicesSearch__days-outstanding-wrapper column"
                        },
                        [
                          _c(
                            "label",
                            {
                              staticClass:
                                "InvoicesSearch__days-outstanding-label uppercase",
                              attrs: { for: "DaysOutstanding" }
                            },
                            [
                              _vm._v(
                                "\n            Days Outstanding\n          "
                              )
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "row" },
                            [
                              _c("base-input", {
                                staticClass:
                                  "InvoicesSearch__days-outstanding-inputs",
                                attrs: {
                                  "data-cy":
                                    "invoice-search-days-outstanding-from",
                                  id: "DaysOutstanding",
                                  instructions:
                                    _vm.instructions
                                      .DAYS_OUTSTANDING_FROM_INSTRUCTION,
                                  "label-for": "daysOutstandingFrom",
                                  placeholder: "From",
                                  type: "text",
                                  valid: _vm.daysOutstandingFromValid
                                },
                                model: {
                                  value: _vm.daysOutstandingFrom,
                                  callback: function($$v) {
                                    _vm.daysOutstandingFrom =
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                  },
                                  expression: "daysOutstandingFrom"
                                }
                              }),
                              _c("base-input", {
                                staticClass:
                                  "InvoicesSearch__days-outstanding-inputs",
                                attrs: {
                                  "data-cy":
                                    "invoice-search-days-outstanding-to",
                                  id: "DaysOutstanding",
                                  instructions:
                                    _vm.instructions
                                      .DAYS_OUTSTANDING_TO_INSTRUCTION,
                                  "label-for": "daysOutstandingTo",
                                  placeholder: "To",
                                  type: "text",
                                  valid: _vm.daysOutstandingToValid
                                },
                                model: {
                                  value: _vm.daysOutstandingTo,
                                  callback: function($$v) {
                                    _vm.daysOutstandingTo =
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                  },
                                  expression: "daysOutstandingTo"
                                }
                              })
                            ],
                            1
                          )
                        ]
                      ),
                      _c("base-toggle", {
                        staticClass:
                          "InvoicesSearch__additional-filters-toggle",
                        attrs: {
                          "active-option": _vm.activeAdditionalFilter,
                          "aria-label": "Additional filter options (toggle)",
                          label: "ADDITIONAL FILTERS",
                          toggles: ["all", "online", "originals", "copies"]
                        },
                        on: { toggle: _vm.setDeliveryRequirements }
                      }),
                      _c("v-select", {
                        staticClass: "InvoicesSearch__predefined-select mt-25",
                        attrs: {
                          "aria-label": "Predefined Searches Select Input",
                          clearable: false,
                          options: _vm.predefinedOptions,
                          placeholder: "Predefined"
                        },
                        on: { input: _vm.setPredefinedSearch },
                        model: {
                          value: _vm.predefinedInput,
                          callback: function($$v) {
                            _vm.predefinedInput = $$v
                          },
                          expression: "predefinedInput"
                        }
                      }),
                      _c("v-select", {
                        staticClass: "InvoicesSearch__flag-select mt-25",
                        attrs: {
                          "aria-label": "Flag Select Input",
                          clearable: false,
                          "data-cy": "approved-search-flag-filter",
                          options: _vm.flagOptions,
                          placeholder: "Flag:"
                        },
                        model: {
                          value: _vm.flagInput,
                          callback: function($$v) {
                            _vm.flagInput = $$v
                          },
                          expression: "flagInput"
                        }
                      })
                    ],
                    1
                  )
                ]
              )
            ],
            1
          )
        : _c(
            "div",
            { staticClass: "InvoicesSearch__search-wrapper" },
            [
              _c("base-debtor-search", {
                staticClass: "InvoicesSearch__debtor-input",
                attrs: {
                  "debtor-i-d-from-query": _vm.debtor_id,
                  label: true,
                  "label-for": "Debtor"
                },
                on: { selected: _vm.setDebtorID },
                scopedSlots: _vm._u([
                  {
                    key: "label",
                    fn: function() {
                      return [_vm._v("\n        DEBTOR\n      ")]
                    },
                    proxy: true
                  }
                ])
              }),
              _c(
                "base-input",
                {
                  staticClass: "InvoicesSearch__query-input",
                  attrs: {
                    instructions: _vm.instructions.QUERY_INSTRUCTION,
                    label: true,
                    "label-for": "Query",
                    type: "text",
                    valid: _vm.queryValid
                  },
                  model: {
                    value: _vm.query,
                    callback: function($$v) {
                      _vm.query = typeof $$v === "string" ? $$v.trim() : $$v
                    },
                    expression: "query"
                  }
                },
                [_vm._v("\n      LOAD #\n    ")]
              ),
              _c(
                "base-button",
                {
                  staticClass: "InvoicesSearch__buttons bg-white fc-blue",
                  on: { click: _vm.clickOnSearch }
                },
                [
                  _c("i", { staticClass: "fa fa-search-left" }),
                  _vm._v("\n      Search\n    ")
                ]
              )
            ],
            1
          ),
      _c(
        "div",
        { staticClass: "row row--align-center mb-25 mt-25 width-100" },
        [
          _vm.invoices.total > 0
            ? _c(
                "label",
                {
                  staticClass: "InvoicesSearch__search-results-label",
                  attrs: { id: "Testing__TotalSearchResults" }
                },
                [
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.invoices.total) +
                      " Total\n      (" +
                      _vm._s(
                        Intl.NumberFormat("en-US", {
                          style: "currency",
                          currency: "USD"
                        }).format(_vm.invoices.sum / 100)
                      ) +
                      ")\n    "
                  )
                ]
              )
            : _c(
                "label",
                {
                  staticClass: "InvoicesSearch__search-results-label",
                  attrs: { id: "Testing__TotalSearchResults" }
                },
                [_vm._v("\n      There are no invoices\n    ")]
              ),
          !_vm.userIsClient
            ? _c("v-select", {
                staticClass: "InvoicesSearch__sort-by-select",
                attrs: {
                  clearable: false,
                  "data-cy": "invoice-search-sort-by",
                  options: _vm.sortByOptions
                },
                on: { input: _vm.setSortBy },
                model: {
                  value: _vm.sortByInput,
                  callback: function($$v) {
                    _vm.sortByInput = $$v
                  },
                  expression: "sortByInput"
                }
              })
            : _vm._e(),
          !_vm.userIsClient
            ? _c("base-toggle", {
                staticClass: "InvoicesSearch__expand-toggle",
                attrs: {
                  "active-option": _vm.expandCollapse,
                  "aria-label": "Collapse or expand items",
                  toggles: _vm.expandCollapseToggles
                },
                on: { toggle: _vm.emitCollapseStateChange }
              })
            : _vm._e(),
          _c(
            "transition",
            { attrs: { mode: "out-in", name: "Transition__opacity-fade" } },
            [
              !_vm.userIsClient && !_vm.reportFinished
                ? _c("div", { key: "loading-indicator" }, [
                    _c(
                      "div",
                      { staticClass: "InvoicesSearch__loading-indicator" },
                      [
                        _c("i", {
                          staticClass:
                            "fa fa-processing fa-spin fa--none fc-light fs-60"
                        })
                      ]
                    )
                  ])
                : !_vm.userIsClient
                ? _c(
                    "base-button",
                    {
                      staticClass: "bg-trans",
                      on: {
                        click: function($event) {
                          _vm.reportModalVisible = true
                        },
                        keydown: function($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          _vm.reportModalVisible = true
                        }
                      }
                    },
                    [
                      _c("i", {
                        staticClass:
                          "InvoicesSearch__aging-icon fa fa-invoice fa-20"
                      })
                    ]
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c(
        "transition",
        { attrs: { name: "Transition__fade" } },
        [
          _vm.reportModalVisible
            ? _c("base-modal", {
                scopedSlots: _vm._u(
                  [
                    {
                      key: "label",
                      fn: function() {
                        return [
                          _vm._v("\n        What format do you want?\n      ")
                        ]
                      },
                      proxy: true
                    },
                    {
                      key: "actions",
                      fn: function() {
                        return [
                          _c(
                            "base-button",
                            {
                              staticClass: "bg-green fc-white mr-8",
                              on: {
                                click: function($event) {
                                  return _vm.downloadInvoiceReport("xlsx")
                                }
                              }
                            },
                            [_vm._v("\n          EXCEL\n        ")]
                          ),
                          _c(
                            "base-button",
                            {
                              staticClass: "bg-red fc-white",
                              on: {
                                click: function($event) {
                                  return _vm.downloadInvoiceReport("pdf")
                                }
                              }
                            },
                            [_vm._v("\n          PDF\n        ")]
                          ),
                          _c(
                            "base-button",
                            {
                              staticClass: "bg-light fc-white ml-8",
                              on: {
                                click: function($event) {
                                  _vm.reportModalVisible = false
                                }
                              }
                            },
                            [_vm._v("\n          CANCEL\n        ")]
                          )
                        ]
                      },
                      proxy: true
                    }
                  ],
                  null,
                  false,
                  3011723821
                )
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }