<template>
  <div class="InvoicesApproved">
    <!-- search -->
    <approved-search
      v-show="!mobile.isMobile"
      class="InvoicesSearch"
      id="Testing__ApprovedSearchSection"
      @collapsed-state-change="expandAll = !expandAll"
      @reset-pagination="resetPagination"
      @sort-by="sort = $event"
      :page="pagination.page"
      :user-is-client="userIsClient"
    />

    <!-- Results (categorized on a client or debtor sort in approved-search) -->
    <base-invoice-table
      :expand-all="expandAll"
      :sort="sort"
      :status="'approved'"
      :user-is-client="userIsClient"
    />

    <base-pagination
      @paginate="paginate"
      class="InvoicesSearch mt-40"
      :item-count="paginationInfo.total"
      :page="pagination.page"
    />
  </div>
</template>

<script>
// Components
import ApprovedSearch from '../../components/approved-search.vue'
import BaseInvoiceTable from '../../components/base-invoice-table.vue'
import BasePagination from '../../components/base-pagination.vue'

/* Dev Notes:
 * All the search functionality (calling the API) is done in approved-search
 * For pagination, this component handles setting and changing pagination and
 *    sends the current page ('page') to approved-search, which is needed to
 *    perform the search API
 */

export default {
  name: 'ApprovedInvoices',

  components: {
    ApprovedSearch,
    BaseInvoiceTable,
    BasePagination,
  },

  props: {
    userIsClient: {
      type: Boolean,
      required: true,
    },
  },

  created () {
    // Check for offset in query string to set page for approved-search
    const queryString = window.location.search
    const queryParams = new URLSearchParams(queryString)

    if (queryString) {
      if (queryParams.has('page')) {
        this.$set(this.pagination, 'page', Number(queryParams.get('page')))
      }
    }
  },

  data () {
    return {
      expandAll: false,
      pagination: {
        page: 1,
        requestLimit: 25,
      },
      sort: 'approved_date_asc',
    }
  },

  computed: {
    mobile () {
      return this.$store.getters.mobile
    },

    paginationInfo () {
      const invoices = this.$store.getters.invoices
      return {
        sum: invoices.sum,
        total: invoices.total,
      }
    },
  },

  methods: {
    paginate (page) {
      this.$set(this.pagination, 'page', page)
      window.scrollTo(0, 0)
    },

    resetPagination () {
      this.$set(this.pagination, 'page', 1)
    },
  },
}
</script>

<style lang="sass" scoped>
@import '../../styles/invoices-search.sass'
</style>
